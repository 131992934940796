import { post } from '@/services/api.service';

const API_URL = '/libraries';

class FileUploaderService {
  upload({ data, config }) {
    return post(API_URL, data, config)
      .then(res => res);
  }
}

export default new FileUploaderService();