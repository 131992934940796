<script>
import fileUploaderServices from '@/services/fileUploader.service'

export default {
  name: 'FileUploader',
  data() {
    return {
      isDropAreaVisible: false,
      isUploaderVisible: false,
      progress: null,
      uploading: false,
      files: [],
      config: {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress:  progressEvent => {
          this.progress = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
        }
      }
    }
  },
  methods: {
    drop(e) {
      e.preventDefault();
      this.files = []
      for( let i = 0; i < e.dataTransfer.files.length; i++ ){
        this.files.push( e.dataTransfer.files[i] );
      }
      this.$emit('filesUpdate', this.files)
      this.isDropAreaVisible = false
      this.uploadFiles()
    },
    handleInputFiles() {
      const files = [...this.$refs.inputFiles.files]
      files.map(file => {
        this.files.push(file)
      })
      this.uploadFiles()
    },
    dragenter () {
      this.isDropAreaVisible = true
    },
    dragleave () {
      this.isDropAreaVisible = false
    },
    toggleUploader() {
      this.isUploaderVisible = !this.isUploaderVisible
    },
    uploadFiles() {
      this.uploading = true
      let formData = new FormData()
      this.files.forEach((file, i) => {
        formData.append('files[' + i + ']', file);
      })
      fileUploaderServices.upload({ data: formData, config: this.config })
        .then(res => {
          this.$notify({
            message: res.data.message,
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this.$emit('successfulUpload')
          this.files = []
          this.progress = null
          this.uploading = false
          this.isUploaderVisible = false
        })
        .catch(_error => {
          this.files = []
          this.progress = null
          this.uploading = false
        })
    }
  }
}
</script>

<template>
  <div class="fileUploader">
    <div
      :class="['progressBar', {'active': uploading}]"
    >
      <div
        class="progressBarInner"
        :style="`transform: translateX(${progress || 0}%)`"
      />
    </div>
    <div class="text-right uploaderArea">
      <BaseButton 
        type="green"
        gradient
        @click="toggleUploader"
      >
        {{ $t('addNewItem', { item: $tc('file', 2) }) }}
      </BaseButton>
    </div>
    <div
      v-if="isUploaderVisible"
      class="uploaderAreaInner"
    >
      {{ $t('clickToAddFilesOrDropThemHere') }}
      <input
        id="inputFiles"
        ref="inputFiles"
        type="file"
        class="inputFiles"
        multiple
        @change="handleInputFiles()"
      >
    </div>
    <div
      :class="['dropAreaContainer', {'active': isDropAreaVisible}]"
      @dragover.prevent.stop="dragenter"
    >
      <div
        v-if="isDropAreaVisible"
        class="dropArea"
        @dragleave.prevent.stop="dragleave"
        @drop.prevent="drop($event)"
      >
        <div class="dropAreaInner">
          {{ $t('dropFilesHere') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.uploaderArea
  position: relative
  z-index: 1
  &Inner
    position: relative
    display: flex
    align-items: center
    justify-content: center
    height: 200px
    margin: $base-spacing 0
    font-size: $s-m
    color: transparentize($c-white, 0.5)
    border: 3px dashed transparentize($c-acc-green, 0.5)

.progressBar
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: $s-s
  overflow: hidden
  background: $blue-gradient
  opacity: 0
  transition: opacity .3s ease-in-out
  &.active
    opacity: 1
  &Inner
    position: absolute
    top: 0
    left: -100%
    width: 100%
    height: 100%
    background: $green-blue-purple-gradient
    transition: transform .3s ease-in-out
    transform: translateX(-50%)
.inputFiles
  position: absolute
  top: 0
  left: 0
  z-index: 100
  width: 100%
  height: 100%
  cursor: pointer
  opacity: 0

.dropAreaContainer
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100vh
  &.active
    z-index: 10000
.dropArea
  position: relative
  z-index: 10000
  width: 100%
  height: 100vh
  background: transparentize($c-acc-green, 0.5)
.dropAreaInner
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  font-size: 30px
  color: $c-white
  border: 3px dashed transparentize($c-white, 0.2)
</style>