<script>
import libraryService from '@/services/library.service'
import libraryTypes from '@/data/enums/libraryTypes.enum'
import Pagination from '@/components/Pagination'
import FileUploader from '@/components/FileUploader'
import lazyType from '@/utils/lazy-type'

export default {
	name: 'LibraryList',
	components: {
		Pagination,
		FileUploader,
	},
	props: {
		limit: {
			type: Number,
			default: null,
			required: true,
		},
		gridItemSize: {
			type: Number,
			default: 200,
		},
		selectItems: {
			type: [Boolean, String], // false, single, multiple
			default: false,
		},
		preselectedItems: {
			type: Array,
			default: () => [],
		},
		fixedType: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			items: [],
			type: '',
			offset: 1,
			totalItems: 0,
			activeTypeTitle: this.$t('allMedia'),
			types: libraryTypes,
			selectedItems: [],
			keyword: '',
			lazyTypeDelay: 200,
		}
	},
	mounted() {
		if (this.preselectedItems) {
			this.selectedItems = [...this.preselectedItems]
		}
		this.limit && this.get()
	},
	beforeDestroy() {
		this.selectedItems = []
		this.items = []
	},
	methods: {
		lazyType,
		get(resetOffset = true) {
			if (resetOffset) this.offset = 1
			if (this.fixedType) this.type = this.fixedType
			libraryService
				.getAll({
					type: this.type,
					limit: this.limit,
					offset: this.offset,
					keyword: this.keyword,
				})
				.then(res => {
					this.items = res.data
					this._detectSelected()
					this.totalItems = Number(res.headers['x-items-count']) || 0
				})
		},
		_detectSelected() {
			if (this.selectedItems.length < 1) {
				return null
			} else {
				this.items.map(item => {
					this.selectedItems.find(selected => {
						if (Number(selected.id) === Number(item.id)) {
							item.selected = true
						}
					})
					return item
				})
			}
		},
		selectPhoto(item) {
			const validOptions = ['single', 'multiple']
			if (!item.selected && validOptions.indexOf(this.selectItems) !== -1) {
				if (this.selectItems === 'single') {
					this.selectedItems = [item]
				} else {
					this.selectedItems.push({ ...item })
				}
				this._detectSelected()
				this.$emit('updateSelected', this.selectedItems)
			}
		},
		deselectPhoto(item, i) {
			this.selectedItems.splice(i, 1)
			let selectedItem = this.items.find(c => c.id === item.id)
			selectedItem ? (selectedItem.selected = false) : (selectedItem = true)
			this.$emit('updateSelected', this.selectedItems)
		},
		filterByType(type) {
			this.activeTypeTitle = type || this.$t('allMedia')
			this.type = type
			this.get()
		},
		filterBySearch() {
			this.get()
		},
		updatePagination(offset) {
			this.offset = offset
			this.get(false)
		},
		confirmRemove(id) {
			if (confirm(this.$t('m.areYouSureYouWantToDeleteThatItem'))) {
				this._remove(id)
			} else {
				this.$notify({
					message: this.$t('m.youDidNotDeleteTheItem'),
					icon: 'far fa-bell',
					horizontalAlign: 'right',
					verticalAlign: 'top',
					type: 'warning',
				})
			}
		},
		_remove(id) {
			libraryService.remove({ id }).then(success => {
				this.$notify({
					message: success.data.message,
					icon: 'far fa-bell',
					horizontalAlign: 'right',
					verticalAlign: 'top',
					type: 'success',
				})
				const index = this.items.findIndex(obj => obj.id === id)
				this.items.splice(index, 1)
			})
		},
	},
}
</script>

<template>
  <BaseCard class="libraryList">
    <FileUploader @successfulUpload="get()" />
    <div class="imagesContainer">
      <div
        class="imagesList"
        :style="`grid-template-columns: repeat(auto-fill, minmax(${gridItemSize}px, 1fr))`"
      >
        <div
          v-for="(item, i) of selectedItems"
          :key="`item${item.id + 'selected'}`"
          class="imagesItem"
        >
          <div
            class="imageContainer"
            :style="`height: ${gridItemSize}px`"
            @click="deselectPhoto(item, i)"
          >
            <BaseImage
              :source="item"
              size="thumb"
              class="image"
              :fill="item.type === 'pdf' ? false : true"
              :fit="item.type === 'pdf' ? true : false"
              :is-pdf="item.type === 'pdf'"
            />
          </div>
        </div>
      </div>
      <div class="row justify-content-between align-items-end filters">
        <div
          v-if="!fixedType"
          class="col-md-3"
        >
          <label class="control-label">{{ $t('filterBy', { item: $tc('type') } ) }}</label>
          <BaseDropdown
            title-classes="btn btn-secondary"
            :title="activeTypeTitle"
          >
            <a
              class="dropdown-item"
              @click="filterByType('')"
            >{{ $t('allMedia') }}</a>
            <a
              v-for="mediaType of types"
              :key="mediaType + 'type'"
              class="dropdown-item"
              @click="filterByType(mediaType)"
            >{{ mediaType }}</a>
          </BaseDropdown>
        </div>
        <div class="col-md-3">
          <BaseInput
            v-model="keyword"
            :placeholder="$t('search')"
            class="search"
            @keyup.prevent.stop="lazyType(filterBySearch, lazyTypeDelay)"
          />
        </div>
      </div>
      <div
        class="imagesList"
        :style="`grid-template-columns: repeat(auto-fill, minmax(${gridItemSize}px, 1fr))`"
      >
        <div
          v-for="item of items"
          :key="`item${item.id}`"
          class="imagesItem"
          :title="item.title"
        >
          <div
            :class="['imageContainer', { selected: item.selected }]"
            :style="`height: ${gridItemSize}px`"
            @click="selectPhoto(item)"
          >
            <BaseImage
              :source="item"
              size="thumb"
              class="image"
              :fill="item.type === 'pdf' ? false : true"
              :fit="item.type === 'pdf' ? true : false"
              :is-pdf="item.type === 'pdf'"
            />
            <div class="optionsOverlay">
              <div
                v-if="item.type !== 'pdf'"
                class="resolution"
              >
                {{ item.width }} x {{ item.height }}
              </div>
              <div
                v-if="item.type === 'pdf'"
                class="title"
              >
                {{ item.title }}
              </div>
              <div
                v-if="selectItems === false"
                class="optionsTools"
              >
                <router-link :to="`/library/${item.id}`">
                  <BaseIcon
                    icons-group="far"
                    icon="fa-edit"
                    class="iconEdit"
                  />
                </router-link>
                <BaseIcon
                  icons-group="fas"
                  icon="fa-ban"
                  class="iconDelete"
                  @click="confirmRemove(item.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="paginationContainer">
        <Pagination
          :offset="offset"
          :total-items="totalItems"
          :limit="limit"
          :total-displayed="items ? items.length : null"
          @updatePagination="updatePagination"
        />
      </div>
    </div>
  </BaseCard>
</template>

<style lang="scss" scoped>
@use '~@/assets/sass/design.sass' as *;

.card {
  margin-bottom: 0;
}

.filters {
  margin-bottom: $base-spacing;
}

.images {
  &List {
    display: grid;
    grid-gap: $base-spacing;
  }

  &Container {
    position: relative;
  }
}

.options {
  &Overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    background: $black-transparent-gradient;
    transition: opacity .3s ease-out;
  }

  &Tools {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    min-height: 40%;
    background: $black-transparent-gradient;
  }
}

.image {
  position: relative;

  &Container {
    position: relative;
    margin-bottom: $base-spacing;
    cursor: pointer;
    box-shadow: $base-shadow;
    background: transparentize($c-acc-green, 0.8);
    background-size: $s-s $s-s;
    background-image:
      linear-gradient(to right, $c-affair-purple 1px, transparent 1px),
      linear-gradient(to bottom, $c-affair-purple 1px, transparent 1px);
    border-radius: 6px;
    overflow: hidden;

    &:hover {
      .optionsOverlay {
        border-radius: 6px;
        opacity: 1;
      }
    }

    &.selected {
      opacity: 0.1;
    }
  }
}

.resolution {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: $s-xxs;
  z-index: $z-highlight;
  text-align: center;
  color: transparentize($c-white, 0.5);
}

.title {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $s-xxs $s-s;
  z-index: $z-highlight;
  text-align: center;
  color: transparentize($c-white, 0.5);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.paginationContainer {
  margin: 0 $s-s;
}

.icon {
  &Edit {
    color: $c-white;

    &:hover {
      color: $c-acc-blue-dark;
    }
  }

  &Delete {
    color: $c-white;
    cursor: pointer;

    &:hover {
      color: $c-acc-red;
    }
  }
}

.search {
  margin-bottom: $base-spacing / 4;
}
</style>
