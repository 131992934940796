<script>
import LibraryList from '@/components/LibraryList'

export default {
  name: "Library",
  components: { LibraryList }
}
</script>

<template>
  <LibraryList
    :limit="35"
  />
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
